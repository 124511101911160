import React from 'react'

import {
  Grid,
  Header,
  Divider,
  Button, Message, Loader
} from 'semantic-ui-react'

import { observer } from 'mobx-react'

import { store } from 'services/state'

import { generateNewApiSecret, getAuthData } from 'services/api-catalog'

export default observer(() => {
  return (
    <Grid container>
      {
        store.apiAppClientsList && store.apiAppClientsList.length !== 0 ? (
          store.apiAppClientsList.map(function (appClient) {
            return <Grid.Row>
              <Grid.Column style={{ paddingTop: '40px' }}>
                <Header size='medium'>{ appClient.name || 'My Application' }</Header>
                <Header size='small'>App client ID</Header>
                <code style={{
                  background: 'black',
                  border: '1px solid gray',
                  padding: '7px 8px',
                  color: 'lightgray',
                  borderRadius: '5px'
                }}
                >
                  {appClient.clientId}
                </code>
                <Header size='small'>Allowed callback URLs</Header>
                {appClient.callbackUrls.map(function (url) {
                  return <><code style={{
                    background: 'black',
                    border: '1px solid gray',
                    padding: '7px 8px',
                    color: 'lightgray',
                    borderRadius: '5px'
                  }}
                  >{ url }</code><Header /></>
                })}
                <Button disabled color='gray' onClick="">
                  Add another URL
                </Button>
              </Grid.Column>
            </Grid.Row>
          })
        ) : (
          <Grid.Row>
            <Grid.Column style={{ paddingTop: '40px' }}>
              <Header size='medium'>Applications</Header>
              <Loader active inline size='tiny'>
                {store.apiAppClientsFetchFailed ? <>
                  Please wait a minute and try refreshing. If this doesn't work, please contact
                  the admin for assistance.
                </> : null}
              </Loader>
            </Grid.Column>
          </Grid.Row>
        )
      }
      <Divider />
      <Grid.Row>
        <Grid.Column>
          <Header size='small'>Identity Server URL</Header>
          <code style={{
            background: 'black',
            border: '1px solid gray',
            padding: '7px 8px',
            color: 'lightgray',
            borderRadius: '5px'
          }}
          >{ getAuthData().identityServerUrl }</code>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Header size='small'>Username</Header>
          <code style={{
            background: 'black',
            border: '1px solid gray',
            padding: '7px 8px',
            color: 'lightgray',
            borderRadius: '5px'
          }}
          >{ getAuthData().email }</code>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {(() => {
            if (store.apiSecretSet === 'requested') {
              return (
                <Loader active inline size='tiny'>Setting things up, please wait a minute</Loader>
              )
            } else if (store.apiSecretSet === 'maybe') {
              return <>
                <Message error>
                  Once you click on the link below, a new password will be
                  generated and your current password will be replaced with it
                </Message>
                <Button compact color='red' onClick={generateNewApiSecret}>
                  Generate and show me my password
                </Button>
              </>
            } else {
              return <>
                <Message info>
                  We created a new password for your application, please copy secret string
                  below and store it in a safe place
                </Message>
                <code style={{
                  background: 'black',
                  border: '1px solid gray',
                  padding: '7px 8px',
                  color: 'lightgray',
                  borderRadius: '5px'
                }}
                >
                  {store.apiSecretSet}
                </code>
              </>
            }
          })()}
        </Grid.Column>
      </Grid.Row>
      <Divider />
    </Grid>
  )
})
